import {
  GridAlignment,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  MuiEvent
} from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCDataGrid from '_components/datagrid/LFCDataGrid';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {fmtYMD} from '_logics/LFCFormatUtil';
import {rendJudgeDisp, rendTotalJudgeDisp} from '_logics/LFCRenderUtil';
import {
  getLFCData,
  getLFCDataProc,
  groupBy,
  handleInputChange,
  handleInputChange2
} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import DetailDig from './components/DetailDig';
import axios from 'axios';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import {ALL} from '_logics/LFCConst';

import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {PAGEID_LQ_INSPECTIONRESULTS, LOCATIONID_LQ_INSPECTIONRESULTS_MAIN} from '_logics/LFCPageId';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 検査結果
 * @returns
 */
const InspectionResultsPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);

  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);

  /**
   * 初期カラム定義
   */
  const BASE_COL: GridColDef[] = [
    {
      field: 'lq_time',
      headerName: `${t('検査日時')}`,
      description: `${t('検査日時')}`,
      width: 140,
      valueFormatter: (prms: GridValueFormatterParams) => fmtYMD(prms.value)
    },
    {
      field: 'serial',
      headerName: `${t('シリアルNo')}`,
      description: `${t('シリアルNo')}`,
      width: 140
    },
    {field: 'work', headerName: `${t('機種')}`, description: `${t('機種')}`, width: 100},
    {
      field: 'deviceid',
      headerName: `${t('検査装置')}`,
      description: `${t('検査装置')}`,
      width: 100
    },
    {
      field: 'user_name',
      headerName: `${t('オペレータ')}`,
      description: `${t('オペレータ')}`,
      width: 100
    },
    {
      field: 'total_judge',
      headerName: `${t('総合判定')}`,
      description: `${t('総合判定')}`,
      width: 80,
      align: 'center',
      disableColumnMenu: true,
      renderCell: (prms: GridRenderCellParams) => rendTotalJudgeDisp(prms.value)
    }
  ];

  const COLUMN_ORDER: any = [
    {
      name: 'lq_time',
      order: 1
    },
    {
      name: 'serial',
      order: 2
    },
    {
      name: 'work',
      order: 3
    },
    {
      name: 'deviceid',
      order: 4
    },
    {
      name: 'total_judge',
      order: 5
    },
    {
      name: 'user_name',
      order: 6
    }
  ];
  /**
   * 検索条件初期値
   */
  const initialSearchValue = {
    date01: dayjs().subtract(7, 'days').format('YYYY-MM-DDT00:00'),
    date02: dayjs().format('YYYY-MM-DDTHH:mm'),
    serialno: '',
    judge: 9,
    work: []
  };
  const [searchValue, setSearchValue] = useState(
    getDefaultCondition(PAGEID_LQ_INSPECTIONRESULTS, LOCATIONID_LQ_INSPECTIONRESULTS_MAIN) ??
      initialSearchValue
  );
  const [dgColumns, setDgColumns] = useState<GridColDef[]>([]);
  const [dgRows, setDgRows] = useState<any>([]);
  const [dgDs, setDgDs] = useState<any>([]);

  // CancelTokenをページに１つ
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  /**
   * レスポンスデータ取得後に明細を展開する
   */
  useEffect(() => {
    if (dgDs.length === 0) {
      setDgColumns([]);
      setDgRows([]);
      return;
    }

    const data = dgDs;

    // 明細Headerをセット
    const dtl_head: string[] = data.shift();
    console.log(dtl_head);

    const judge_align: GridAlignment = 'center';
    setDgColumns([
      ...BASE_COL,
      ...dtl_head
        .filter((v: string, i: number) => 6 < i) //固定項目以降を抽出
        .sort()
        .map((v: string) => {
          return {
            field: v,
            headerName: v,
            width: 100,
            align: judge_align,
            headerAlign: judge_align,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (prms: GridRenderCellParams) => rendJudgeDisp(prms.value)
          };
        })
    ]);

    // 明細Body用にデータ加工（CSV形式→JSONに変換）
    const dtl_body = data.map((values: [], i: number) => {
      return dtl_head.reduce(
        (acc2: any, key: string, j: number) => {
          acc2[key] = values[j];
          return acc2;
        },
        {id: i}
      );
    });
    console.log(dtl_body);
    const _dtl_body = [
      ...dtl_body.filter((v: string, i: number) => 6 >= i),
      ...dtl_body.filter((v: string, i: number) => 6 < i).sort()
    ];

    setDgRows(_dtl_body);
  }, [dgDs]);

  /**
   * シリアルNo入力時の他の検索値をクリアする
   */
  useEffect(() => {
    if (searchValue.serialno !== '') {
      setSearchValue({...searchValue, date01: '', date02: '', judge: 9, work: []});
    }
  }, [searchValue.serialno]);

  /**
   * 検索処理
   */
  const doSearch = () => {
    // input check
    if (!searchValue.serialno) {
      if (!formRef.current?.reportValidity()) {
        return;
      }
    }

    setDgDs([]);

    setStartProcess(true);
    Promise.allSettled([
      getLFCDataProc({
        snack: enqueueSnackbar,
        //sql_id: 6702,
        invoke_name: 'LWAppResultSearch',
        parameters: {
          serial: searchValue.serialno === '' ? null : `%${searchValue.serialno}%`,
          date1: searchValue.date01 === '' ? null : searchValue.date01,
          date2: searchValue.date02 === '' ? null : searchValue.date02,
          judge: searchValue.judge === 9 ? null : searchValue.judge,
          work: isEmpty(searchValue.work) ? null : '{' + searchValue.work.join(',') + '}'
        },
        ds_state: setDgDs,
        name: `${t('検索結果')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  /**
   * リセット処理
   */
  const restSearch = () => {
    setStartProcess(false);
    setSearchValue(initialSearchValue);
    setDgDs([]);
    setAutoCompleteReset(true);
  };

  const [open, setOpen] = React.useState(false);
  const [openUuid, setOpenUuid] = React.useState('');
  const [openMap, setOpenMap] = React.useState('');

  /**
   * 明細選択
   * @param params
   * @param event
   * @param details
   */
  const goDetail = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    setOpenUuid(params.row.uuid);
    setOpenMap(params.field);
    setOpen(true);
  };

  const [condition, setCondition] = React.useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    // input check
    if (!searchValue.serialno) {
      if (!formRef.current?.reportValidity()) {
        return;
      }
    }
    setCondition([
      {
        name: 'date01',
        value: searchValue.date01,
        valueLabel:
          searchValue.date01 === '' ? '' : dayjs(searchValue.date01).format('YYYY-MM-DD HH:mm'),
        colName: t('日付From'),
        colWidth: 150
      },
      {
        name: 'date02',
        value: searchValue.date02,
        valueLabel:
          searchValue.date02 === '' ? '' : dayjs(searchValue.date02).format('YYYY-MM-DD HH:mm'),
        colName: t('日付To'),
        colWidth: 150
      },
      {
        name: 'judge',
        value: searchValue.judge,
        valueLabel: searchValue.judge === 9 ? t('すべて') : searchValue.judge === 0 ? 'OK' : 'NG',
        colName: t('総合判定'),
        colWidth: 100
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      },
      {
        name: 'serialno',
        value: searchValue.serialno,
        valueLabel: searchValue.serialno,
        colName: t('シリアルNo'),
        colWidth: 300
      }
    ]);

    setOpenSavedCondition(true);
  };
  // 保存した検索条件画面で検索条件が選択された
  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setDgRows([]);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  useEffect(() => {
    // マウント時処理
    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work]);

  return (
    <GenericTemplate title={t('menu.page_name.検査結果検索')}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <LFCDatetimePicker
            name="date01"
            label={t('日付From')}
            value={searchValue.date01}
            onChange={event => {
              if (searchValue.serialno === '') {
                handleInputChange(event, searchValue, setSearchValue);
              }
            }}
            required
          />
          <LFCDatetimePicker
            name="date02"
            label={t('日付To')}
            value={searchValue.date02}
            onChange={event => {
              if (searchValue.serialno === '') {
                handleInputChange(event, searchValue, setSearchValue);
              }
            }}
            required
          />
          <LFCSelectFormJudge
            name={'judge'}
            value={searchValue.judge}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small" // small/medium
            value={multiSelectData(work, searchValue.work)}
            // style={{width: 500}}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCTextField
            name="serialno"
            label={t('シリアルNo')}
            value={searchValue.serialno}
            onChange={event => {
              handleInputChange(event, searchValue, setSearchValue);
            }}
          />
          <LFCButton color="primary" onClick={doSearch}>
            {t('検索')}
          </LFCButton>
          <LFCButton onClick={restSearch}>{t('リセット')}</LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_LQ_INSPECTIONRESULTS}
            locationNo={LOCATIONID_LQ_INSPECTIONRESULTS_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <LFCDataGrid
        columns={dgColumns}
        rows={dgRows}
        height="80vh"
        onCellClick={goDetail}
        exportFilename={`${t('検査結果検索')}`}
        columnOrder={COLUMN_ORDER}
        style={{cursor: 'pointer'}}
        initialState={{
          left: ['lq_time', 'serial', 'work', 'deviceid', 'user_name', 'total_judge']
        }}
        pageSize={500}
      />
      <DetailDig open={open} onClose={() => setOpen(false)} uuid={openUuid} map={openMap} />
    </GenericTemplate>
  );
};

export default InspectionResultsPage;
